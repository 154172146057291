jQuery(document).on({
	click: function () {
		var toShow = '#' + jQuery(this).attr('data-product');
		jQuery('.selector-numbers a.active').removeClass('active');
		jQuery('.selector-bottom a.active').removeClass('active');

		jQuery('.selector-numbers, .selector-bottom').find('a[data-product="' + jQuery(this).attr('data-product') + '"]').addClass('active');

		jQuery('.pac-rounded-back').stop().animate({ bottom: '-510px', opacity: 0 }, 500);

		jQuery('.pac-image, .pac-content').stop().fadeOut(500, function() {
			jQuery(toShow).find('.pac-rounded-back').stop().animate({ bottom: '0', opacity: 1 }, 500);
			jQuery(toShow).find('.pac-image, .pac-content').stop().fadeIn(500);
		});
	}
}, '.selector-numbers a, .selector-bottom a');


function resize_bg_prodotto(){
	var width_product = jQuery('.products-animation').width();
	jQuery('.products-animation').height(width_product);
}

if( jQuery(window).width() < 768 ){
jQuery( document ).ready(function(){
	resize_bg_prodotto();
});

jQuery( window ).resize(function(){
	resize_bg_prodotto();
});
}
