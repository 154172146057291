jQuery(document).ready(function($) {
	startMap();
});

function performFiltering() {
	var country = jQuery('#country-search').val().toLowerCase();
	var type = jQuery('#type-search').val().toLowerCase();
	var name = jQuery('#name-search').val().toLowerCase();

	var viableItems = jQuery('.ls-item');

	if(country) {
		console.log(country);
		viableItems = viableItems.filter("[datacountry*='" + country + "']");
	}

	if(name) {
		console.log(name);
		viableItems = viableItems.filter("[dataname*='" + name + "']");
	}

	if(type !== 'all') {
		console.log(country);
		viableItems = viableItems.filter("[datatype*='" + type + "']");
	}

	jQuery('.ls-item').each(function() {
		jQuery(this).hide();
		hideMarker(jQuery(this).attr('datapin'));
	});

	viableItems.each(function() {
		jQuery(this).show();
		showMarker(jQuery(this).attr('datapin'));
	});
}

jQuery('#country-search').keyup(function() { performFiltering(); jQuery('.location-items').slideDown(300); });
jQuery('#type-search').change(function() { performFiltering(); jQuery('.location-items').slideDown(300); });
jQuery('#name-search').keyup(function() { performFiltering(); jQuery('.location-items').slideDown(300); });

jQuery('#country-search').on('input', function () {
    if (this.value === '') {
		performFiltering();
    }
}, false);

jQuery('#name-search').on('input', function () {
    if (this.value === '') {
		performFiltering();
    }
}, false);

jQuery(document).on({
    click: function (e) {
		e.preventDefault();
		jQuery('.location-items').slideToggle(300);
    }
}, '#list-toggle');

jQuery(document).on({
    click: function (e) {
		jQuery(this).toggleClass('open-item');
		jQuery(this).find('.ls-item-contacts').slideToggle(300);
    }
}, '.ls-item');
