jQuery(document).on({
	mouseenter: function () {
		jQuery(this).find(".pb-details").stop().slideDown(300);
	},
	mouseleave: function () {
		jQuery(this).find(".pb-details").stop().slideUp(300);
	}
}, '.plant-badge');

jQuery(document).ready( function() {
  var owl = jQuery(".divisions-carousel");
  //var owlInner = jQuery(".product-gallery-container");

  owl.owlCarousel({
	items: 4,
	itemsDesktop : [1199,4],
	itemsDesktopSmall : [979,2],
	navigation : false,
	pagination: false,
	stopOnHover: true,
	autoPlay: 4000
	/*navigationText : [
	    '<img src="/wp-content/uploads/2016/04/owl-left.png" />',
	    '<img src="/wp-content/uploads/2016/04/owl-right.png" />'
	]*/
  });

  owl.trigger('owl.play',4000);

  /*owlInner.owlCarousel({

	items: 1,
	itemsDesktop : [1199,1],
	itemsDesktopSmall : [979,1],
	navigation : false
  });

  owlInner.trigger('owl.play', 3500);*/
});
