jQuery('.exes-animation').waypoint(function(direction) {
		setTimeout(function(){
		    jQuery('.ea-step1 div').animate({ top: 0, opacity: 1 }, 300, function(){
				setTimeout(function() {
					jQuery('.ea-step2 div').each(function(i){
						jQuery(this).delay(i * 300).animate({ opacity: 1 }, 400);
					});
				}, 400);
			});
		}, 1500);
	}, {
		offset: '85%'//'bottom-in-view'
	}
);

jQuery(document).on({
	mouseenter: function () {
		var popup = jQuery("[data-popup='" + this.id + "']");

		popup.stop().fadeIn(300);
	},
	mouseleave: function () {
		var popup = jQuery("[data-popup='" + this.id + "']");

		popup.stop().fadeOut(300);
	}
}, '.ea-dot');
