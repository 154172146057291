if( jQuery(window).width() > 768 ) {
	jQuery('.equal-columns').each(function() {
		var h = 0;

		jQuery(this).find('> div').each(function() {
			if(jQuery(this).height() > h) {
				h = jQuery(this).innerHeight();
			}
		});

		jQuery(this).find('> div').innerHeight(h);
	});
}
