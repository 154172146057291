if( jQuery(window).width() > 768 ) {
	jQuery(".history-item").flip({
		axis: 'y',
		trigger: 'hover',
		speed: 700
	});
}
else {
	jQuery(".history-item").flip({
		axis: 'y',
		trigger: 'click',
		speed: 700
	});
}
