var map = null;
var tmpZoom = 6;
var infowindow = null;
var markers = [];
var markersIndex = [];
var markersOptions = [];
var markerClusterer = null;
var infowindowList = [];
var iconMarkerUrl = "<?php echo get_stylesheet_directory_uri(); ?>/theme/assets/images/iconMarker.png";

function initializeMap(mapElement) {
    //console.log("initializeMap()");

    var styles = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"on"},{"saturation":"-69"},{"lightness":"49"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"transit.station.rail","elementType":"all","stylers":[{"lightness":"91"},{"visibility":"simplified"},{"saturation":"43"},{"gamma":"0.00"},{"weight":"4.66"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#898989"},{"visibility":"on"}]}];

	var styledMap = new google.maps.StyledMapType(styles, { name: "New Stamen Map" });

	var options = {
		center: new google.maps.LatLng(41.87194,12.56738),
			/*parseFloat(document.getElementById("longitude").value),
			parseFloat(document.getElementById("latitude").value)),*/
		zoom: 4,
		mapTypeControlOptions: {
			mapTypeIds: [google.maps.MapTypeId.ROADMAP /*, 'map_style'*/]
		},
		zoomControl: true,
		mapTypeControl: false,
		zoomControlOptions: {
			style: google.maps.ZoomControlStyle.LARGE,
			position: google.maps.ControlPosition.LEFT_CENTER
		},
		navigationControl: true,
		streetViewControl: true,
		draggable: true,
		scrollwheel: false,
	};

	map = new google.maps.Map(document.getElementById(mapElement), options);
	//console.log('test');

	map.mapTypes.set('map_style', styledMap);
	map.setMapTypeId('map_style');

}

function map_recenter(latlng,offsetx,offsety) {
    var point1 = map.getProjection().fromLatLngToPoint(
        (latlng instanceof google.maps.LatLng) ? latlng : map.getCenter()
    );
    var point2 = new google.maps.Point(
        ( (typeof(offsetx) === 'number' ? offsetx : 0) / Math.pow(2, map.getZoom()) ) || 0,
        ( (typeof(offsety) === 'number' ? offsety : 0) / Math.pow(2, map.getZoom()) ) || 0
    );
    map.panTo(map.getProjection().fromPointToLatLng(new google.maps.Point(
        point1.x - point2.x,
        point1.y + point2.y
    )));
}

function addMarker(position) {

	var marker = new google.maps.Marker({
		position: position[0],
		draggable: false,
		//animation: google.maps.Animation.DROP,
		//flat: false,
		icon: position[7],
		title: position[1],
		metadata: { id: position[2] },
		zIndex: position[11],
		cat: position[12],
	});
	//marker.metadata = { id: position[2] };
	markers.push(marker);
	markersIndex[position[2]] = marker;
	//markersIndex["store-item-" + position[2]] = marker;
	//console.log(marker);

	//$('#store-item-' + position[2]).removeClass('highlight');
	if(position[10].length > 1) {
		google.maps.event.addListener(marker, 'displaywindow', function() {
			var contentString = "<div class='almainfobox'>";
			contentString += "<div class='infobox-content'>";
			contentString += position[10];
			contentString += "</div>";

			contentString += "</div></div>";
			//console.log(position);
			if (infowindow) {
				infowindow.close();
			}

			var myOptions = {
				content: contentString,
				disableAutoPan: false,
				maxWidth: 0,
				pixelOffset: new google.maps.Size(-165, -50),
				zIndex: null,
				boxStyle: {
					width: "350px",
				},
				closeBoxMargin: "5px",
				closeBoxURL: "/wp-content/uploads/2016/04/close-btn.jpg",
				infoBoxClearance: new google.maps.Size(1, 1),
				isHidden: false,
				pane: "floatPane",
				enableEventPropagation: false,
				alignBottom: true
			};

			infowindow = new InfoBox(myOptions);
			infowindow.open(map, marker);

			//infowindow.setOptions({'pixelOffset': new google.maps.Size(-175, -40)});
		});
	}

	google.maps.event.addListener(marker, 'click', function() {
		new google.maps.event.trigger( marker, 'displaywindow' );
		//map.setCenter(marker.getPosition());
		if(map.getZoom() < 3){
			map.setZoom(3);
		}
		map_recenter(marker.getPosition(), 0, 0);
	});
}

function refreshMap() {
	for (var i = 0; i < markers.length; i++) {
		markers[i].setMap(map);
	}
}

function searchstart(type) {
	var link = '/wp-admin/admin-ajax.php?action=getlocations';

	if(type === 'hq') {
		var link = '/wp-admin/admin-ajax.php?action=getheadquarter';
	}

	if(type === 'cases') {
		var link = '/wp-admin/admin-ajax.php?action=getcases';
	}

	if(type === 'contacts') {
		var link = '/wp-admin/admin-ajax.php?action=getcontacts';
	}

	jQuery.getJSON(link, function( data ) {
		markers = [];

		jQuery.each(data, function(key, val) {
			addMarker([new google.maps.LatLng(val.latitude, val.longitude), val.post_title, val.ID, val.website, val.citta, val.indirizzo, val.cap, val.pin, val.icon, val.location_img, val.content, val.zindex, val.cat]);
		});

		var bounds = new google.maps.LatLngBounds();

		if (markers.length > 0) {

			if (tmpZoom === 6) {
				bounds = new google.maps.LatLngBounds();
				for ( i = 0; i < markers.length; i++) {
					bounds.extend(markers[i].getPosition());
				}
				map.fitBounds(bounds);
			}

			if (map.getZoom() < 14) {
				refreshMap();
			}
			else {
				for ( i = 0; i < markers.length; i++) {
					markers[i].setMap(map);
				}
			}
		}
	});
}

function addHQMarker(position) {

	var marker = new google.maps.Marker({
		position: position[0],
		draggable: false,
		icon: position[7],
		title: position[1],
		metadata: { id: position[2] }
	});

	markers.push(marker);
	markersIndex[position[2]] = marker;

	var contentString = "<div class='casesinfobox' style='background-image: url(" + position[9] + "); background-position: 30px 90px;'>";
	contentString += "<div class='infobox-content' style='background-image: url(" + position[8] + ");  background-position: 30px 90px;'><div class='title'>" + position[1] + "</div>";
	contentString += "<span class='infowindow-address'>" + position[5] + " |</span>";
	contentString += "<span class='infowindow-cap-city'>" + position[6] + " <span>|</span> <strong>" + position[4] + "<strong></span>";
	contentString += "<div class='contact'><div class='phone'>" + position[2] + "</div><div class='email'><a href='mailto:" + position[3] + "'>" + position[3] + "</a></div></div>";

	contentString += "</div></div>";
	//console.log(position);
	if (infowindow) {
		infowindow.close();
	}

	var myOptions = {
		content: contentString,
		disableAutoPan: false,
		maxWidth: 0,
		pixelOffset: new google.maps.Size(100, -150),
		zIndex: null,
		boxStyle: {
			width: "360px",
			height: "200px",
		},
		closeBoxMargin: "0",
		closeBoxURL: "/wp-content/themes/olsa/assets/images/close-btn.jpg",
		infoBoxClearance: new google.maps.Size(1, 1),
		isHidden: false,
		pane: "floatPane",
		enableEventPropagation: false
	};

	infowindow = new InfoBox(myOptions);
	infowindow.open(map, marker);

	if (infowindow && (jQuery(window).width() < 768)) {
		infowindow.close();
	}

	map.panTo(marker.getPosition());
	map.setZoom(14);
	//map_recenter(marker.getPosition(), -195, 0);

	google.maps.event.addListener(infowindow, 'closeclick', function() {
		map_recenter(markers[0].getPosition(), 0, 0);
	});

	google.maps.event.addListener(marker, 'click', function() {
		infowindow.close();
		infowindow.open(map, marker);

		map_recenter(marker.getPosition(), -195, 0);
	});
}

function displayHeadquarters()
{
	var link = '/wp-admin/admin-ajax.php?action=getotusheadquarter';

	jQuery.getJSON(link, function( data ) {
		markers = [];

		jQuery.each(data, function(key, val) {
			addHQMarker([new google.maps.LatLng(val.latitude, val.longitude), val.post_title, val.telefono, val.email, val.citta, val.indirizzo, val.cap, val.pin, val.icon, val.location_img]);
		});

		if (markers.length > 0) {
			for ( i = 0; i < markers.length; i++) {
				markers[i].setMap(map);
			}
		}
	});
}

function displayPin(pid)
{
	var link = '/wp-admin/admin-ajax.php?action=getstilmasgeoloc&pid=' + pid;

	jQuery.getJSON(link, function( data ) {
		markers = [];

		jQuery.each(data, function(key, val) {
			addHQMarker([new google.maps.LatLng(val.latitude, val.longitude), val.post_title, val.telefono, val.email, val.citta, val.indirizzo, val.cap, val.pin, val.icon, val.location_img]);
		});

		if (markers.length > 0) {
			for ( i = 0; i < markers.length; i++) {
				markers[i].setMap(map);
			}
		}
	});
}

function showMarker(id) {
	markersIndex[id].setMap(map);
}

function hideMarker(id) {
	markersIndex[id].setMap(null);
}

function startMap() {
	// If a Map Canvas exists
	if ( jQuery( "#map-canvas" ).length ) {
		initializeMap('map-canvas');

		if( jQuery( "#map-canvas" ).attr('datatype') === 'casehistories') {
			searchstart('cases');
		}

		if( jQuery( "#map-canvas" ).attr('datatype') === 'contacts') {
			searchstart('contacts');
		}

		if( jQuery( "#map-canvas" ).attr('datatype') === 'world') {
			searchstart();
		}

		if( jQuery( "#map-canvas" ).attr('datatype') === 'hq') {
			searchstart('hq');
		}

		//console.log(markersIndex);

		jQuery('.ls-item').on('click', function (){
			//console.log(jQuery(this).attr('datapin'));
			//console.log(markersIndex[jQuery(this).attr('datapin')]);

			new google.maps.event.trigger( markersIndex[jQuery(this).attr('datapin')], 'click' );
		});
	}
}
