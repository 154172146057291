/*jQuery(".application-item").flip({
	axis: 'y',
	trigger: 'manual',
	speed: 700
});*/

jQuery(document).on({
	click: function () {
		var toOpen = jQuery(this).parent();

		jQuery('.ap-front').slideDown(500, function(){
			toOpen.stop().slideUp(500);
		});
	}
}, '.ap-front img');
